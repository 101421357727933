import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent } from '@mui/material';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';
import axios from './api'
import { useEffect } from 'react';
import uploads_links from '../../plugins/shared-links';

export default function DoctorsModal({ text, icon, id }) {
    const [open, setOpen] = React.useState(false);
    const [specialties, setSpecialties] = useState([])
    const [partners, setPartners] = useState([])
    useEffect(() => {
        axios.getPartners().then((res) => {
          setPartners(res.data.data)
        })
        axios.getSpecialties().then((res) => {
            setSpecialties(res.data.data)
          })
    }, []);

    const [src, setSrc] = React.useState('');
    var data = new FormData();
    const [file,setFile]=useState('')
    const [nameEn,setNameEn]=useState('')
    const [nameAr,setNameAr]=useState('')
    const [partnerId,setPartnerId]=useState(1)
    const [positionEn,setPositionEn]=useState('')
    const [positionAr,setPositionAr]=useState('')

    const handleClickOpen = () => {
        data.append('id', id)
        if (text === 'Edit') {
            axios.getDoctorById(data).then((res) => {
                setOpen(true);
                setSrc(uploads_links[1]+res.data.data.picture)
                setNameAr(res.data.data.name_ar)
                setNameEn(res.data.data.name_en)
                setPartnerId(res.data.data.partner_id)
                setPositionEn(res.data.data.position_en)
                setPositionAr(res.data.data.position_ar)
            })
        }
        else {
            setOpen(true)
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOnConfirm = ()=>{
        if(text==='Delete'){
            DeleteDoctor()
        }else if(text==='Edit'){
            EditDoctor()
        }else{
            AddDoctor()
        }
    }
    const AddDoctor = () => {
        data.append('picture', document.querySelector('input[type=file]')['files'][0]);
        data.append('name_en', document.getElementById('doctor-name-en').value);
        data.append('name_ar', document.getElementById('doctor-name-ar').value);
        data.append('partner_id', partnerId);
        data.append('position_en', document.getElementById('doctor-position-en').value);
        data.append('position_ar', document.getElementById('doctor-position-ar').value);

        axios.addDoctor(data).then((res) => {
            window.location.reload(true);
            // console.log(res.data.data)
            
        })
        setOpen(false);
    };
    const EditDoctor = () => {
        console.log(id)
        data.append('id', id)
        data.append('picture', document.querySelector('input[type=file]')['files'][0]);
        data.append('name_en', document.getElementById('doctor-name-en').value);
        data.append('name_ar', document.getElementById('doctor-name-ar').value);
        data.append('partner_id', partnerId);
        data.append('position_en', document.getElementById('doctor-position-en').value);
        data.append('position_ar', document.getElementById('doctor-position-ar').value);
        axios.editDoctor(data).then((res) => {
           window.location.reload(true);
           //  console.log(res.data.data)
            
        }).catch(function (error) {
            console.log(error)
        });
        setOpen(false);
    };
    const DeleteDoctor = () => {
        data.append('id', id)
        axios.deleteDoctor(data).then((res) => {
            console.log(res)
            window.location.reload(true);
        }).catch(function (error) {
            console.log(error)
        });
        setOpen(false);
    };

    const arr = partners?.map((partner) => {
        return (
            <MenuItem value={partner.id}>{partner.name_en}</MenuItem>
        )
    })
    const availableSpecialties = specialties?.map((specialty) => {
        return (
            <MenuItem value={specialty.id}>{specialty.name_en}</MenuItem>
        )
    })

    function imageUploaded() {
        var base64String
        var file = document.querySelector(
            'input[type=file]')['files'][0];
      
        var reader = new FileReader();
          
        reader.onload = function () {
            base64String = reader.result 
            setSrc(base64String)
        }
        reader.readAsDataURL(file);
    }

    return (
        <div className='Buttontest-container'>
            <button className='fs16' onClick={handleClickOpen}>
                {text == 'Add' ? text : ''}<i className={icon == 'Edit' ? 'fa fa-pen' : icon == 'Delete' ? 'fa fa-trash' : ''}></i>
            </button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{text} Doctor</DialogTitle>
                <DialogContent >
                    {icon !== 'Delete' && <div>
                        <img src={src} alt='' />
                        <input type='file' accept="image/png, image/jpeg" onChange={imageUploaded} />
                        <div className='dialog-row'>
                            <TextField
                                margin="dense"
                                id="doctor-name-en"
                                defaultValue={nameEn}
                                label="Name in English"
                                type="email"
                                fullWidth
                                variant="standard"
                            />
                            <TextField
                                margin="dense"
                                defaultValue={nameAr}
                                id="doctor-name-ar"
                                label="Name in Arabic"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                        <FormControl variant="standard" sx={{ minWidth: '100%' }}>
                            <InputLabel id="Dr-partner-label">Choose The Partner</InputLabel>
                            <Select
                                id='choose-partner'
                                defaultValue={partnerId}
                                onChange= {(e)=>{setPartnerId(e.target.value)}}
                                labelId='Dr-partner-label'
                                variant='standard'
                                sx={{ width: '100%', marginTop: '30px' }}
                            >
                                {arr}
                            </Select>
                        </FormControl>
                        <TextField
                            margin="dense"
                            id="doctor-position-en"
                            defaultValue={positionEn}
                            label="Doctor's Position English"
                            type="email"
                            fullWidth
                            variant="standard"
                        />
                        <TextField
                            margin="dense"
                            id="doctor-position-ar"
                            defaultValue={positionAr}
                            label="Doctor's Position Arabic"
                            type="email"
                            fullWidth
                            variant="standard"
                        />

                    </div>}
                    {icon == 'Delete' && <p>Are you sure you want to delete it?</p>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleOnConfirm}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
