import * as React from 'react';
import data from '../../assets/data/treatments';
import './Admin.css'
import TreatmentsModal from '../../components/Modal/treatments-modal';
import AdminDataGrid from '../../components/AdminDataGrid/AdminDataGrid';
import { useEffect } from 'react';
import axios from './api'
import { useState } from 'react';
import uploads_links from '../../plugins/shared-links';

export default function Treatments() {
    const Button = (params) => {
        return (
            <TreatmentsModal text={params.field} icon={params.field} id={params.row.id}/>
        )
    }
    const [rows, setRows] = useState([])
    useEffect(() => {
        axios.getTreatments().then((res) => {
          //  setSettingsData(res.data.data)
          setRows(res.data.data)
        }).catch(function (error) {
            console.log(error)
        });
    },[])

    const columns = [
        {
            field: 'icon',
            headerName: 'Icon',
            renderCell: (params) => <img className='admin-treat-icon' src={uploads_links[0]+params.value} alt=''/>,
            flex: 1,
        },
        {
            field: 'name_en',
            headerName: 'Name En',
            flex: 1,
        },
        {
            field: 'name_ar',
            headerName: 'Name Ar',
            flex: 1
        },
        {
            field: 'Edit',
            headerName: 'Edit',
            renderCell: Button
        },
        {
            field: 'Delete',
            headerName: 'Delete',
            renderCell: Button
        }
    ];

    return (
        <div style={{ height: 700, width: '100%' }}>
            <div className='Title-container'>
                <div className='fs38 Admin-title'>Treatments</div>
                <TreatmentsModal text='Add' />
            </div>
            <AdminDataGrid columns={columns} rows={rows} />
        </div>
    );
}