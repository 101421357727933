import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import logo from '../../assets/images/Logo.svg'
import './AdminLogIn.css'
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const AdminLogin = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('default');
    const [pass, setPass] = useState('default');

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        var user = {
            email: data.get('email'),
            password: data.get('password'),
        }
        if (email === 'LEAD' && pass === 'LEAD2022') {
            localStorage.setItem('user', JSON.stringify({
                email: data.get('email'),
                password: data.get('password'),
            }));
            const saved = localStorage.getItem('user')
            console.log(saved)
            navigate('/admin/treatments')
        }
    };
    const handleEmail = (e) => {
        setEmail(e.target.value.toString())
    }

    const handlePassword = (e) => {
        setPass(e.target.value.toString())
    }

    return (
        <div className='logIn-container'>
            <div>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img src={logo} alt='' />
                    <Typography sx={{
                        fontSize: '38px',
                        color: 'var(--second-color)',
                        fontFamily: 'var(--second-font)'
                    }}>
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            defaultValue=''
                            onFocus={handleEmail}
                            onChange={handleEmail}
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            error={email === ''}
                        />
                        <TextField
                            defaultValue=''
                            onFocus={handlePassword}
                            onChange={handlePassword}
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            error={pass === ''}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, borderRadius: 100 }}
                        >
                            Sign In
                        </Button>
                    </Box>
                </Box>
            </div>
        </div>
    );
}

export default AdminLogin