import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import homeBanner1 from '../../assets/images/home-banner-01.jpg';
import homeBanner2 from '../../assets/images/banner-Cardiovascular-Care.jpg';
import homeBanner3 from '../../assets/images/banner-Cerebral-Palsy-Spasticity-Rehabilitation.jpg';
import homeBanner4 from '../../assets/images/banner-Hair-Loss-Treatment.jpg';
import homeBanner5 from '../../assets/images/banner-Hollywood-Smile.jpg';
import homeBanner6 from '../../assets/images/banner-Vision-Correction.jpg';

import './home.css'
import './arHome.css'
import TreatmentCard from '../../components/TreatmentCard/TreatmentCard';
import { useTranslation } from 'react-i18next';
import data from '../../assets/data/treatments';
import AdditionalService from '../../components/AdditionalServices/AdditionalService';
import servicesData from '../../assets/data/AdditionalServices';
import CarouselCaption from '../../components/CarouselCaption/CarouselCaption';
import detailImg from '../../assets/images/Mask Group 2.png'
import data1 from './testimonial-data';
import axios from './api'
import uploads_links from '../../plugins/shared-links';
import vision from '../../assets/images/noun-vision-3234414.svg'
import rocket from '../../assets/images/noun-rocket-5051791.svg'
import diamond from '../../assets/images/noun-diamond-1060133.svg'
import { useNavigate } from 'react-router-dom';
const Home = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const [treatments, setTreatments] = useState()
    useEffect(() => {
        axios.getTreatments().then((res) => {
            //  setSettingsData(res.data.data)
            setTreatments(res.data.data)
        })
    }, [])
    const arr = treatments?.map((treat, index) => {
        return (
            <TreatmentCard key={index} forward={treat.id} linked={treat.linked} src={uploads_links[0] + treat.icon} text={t('treatment' + treat.id + 'name')} />
        )
    })
    const Additionls = servicesData.map((service, index) => {
        const tootltipText = service['tootltipText' + i18n.language]
        return (
            <AdditionalService key={index} src={service.src} text={t(service.text)} tootltipText={t(tootltipText)} />
        )
    })
    const CarouselCaptions = data1[i18n.language]?.map((caption, index) => {
        return (
            <CarouselCaption key={index} title={t('testimonials')} text={caption.text} signiture={caption.name} />
        )
    })
    return (
        <div className={i18n.resolvedLanguage + ' home-page-container'} key={i18n.resolvedLanguage}>
            <OwlCarousel autoplay={true} autoplayTimeout={3000} autoplayHoverPause={true} className='owl-theme' items={1} loop margin={10} nav>
                {[homeBanner1, homeBanner2, homeBanner3, homeBanner4, homeBanner5, homeBanner6].map((element, index) => {
                    return <div className='item image-layer'>
                        <img src={element} alt='Home banner 1st ' />
                        <div className={i18n.resolvedLanguage + ' home-image-caption'}>
                            <h1 className='home-image-caption-title1 fs74'>{t('homePageImg0' + (index + 1) + '_1')} </h1>
                            <h1 className='home-image-caption-title2 fs74'>{t('homePageImg0' + (index + 1) + '_2')}</h1>
                            <button onClick={() => { navigate('/treatments/1') }} className='fs18 Bold Booking-button'>{t('homePageBannerBtn01')}</button>
                        </div>
                    </div>
                })}

            </OwlCarousel>
            <div className='global-container lead-description'>
                <div className='fs38 description-item lead-description-title Bold'>{t('who-we-are-title')}</div>
                <div className='fs18 description-item justifyText'>{t('who-we-are-text')}</div>
            </div>
            <div className='treatments-container'>
                <h1 className='treat-titles'>{t('our-treatments-title')}</h1>
                <div className='treatments-options-container'>
                    {arr}
                </div>
            </div>
            <div className='Aditional-Services'>
                <div className='fs38 source-pro '>{t('additional-services-title')}</div>
                <div className='services-icon-container'>
                    <div className='service'>
                        {Additionls}
                    </div>
                </div>
            </div>
            <div className='global-container lead-details'>
                <div className='detail-image'>
                    <img src={detailImg} alt='' />
                </div>
                <div className='details-table '>
                    <div className='detail-row'>
                        <div className='detail-title fs20 Bold'>
                            <div><img src={vision} alt='' /></div>
                            <div>{t('vision-title')}</div>
                        </div>
                        <div className='detail-description fs18'>{t('vision-text')}</div>
                    </div>
                    <div className='horizental-line' />
                    <div className='detail-row'>
                        <div className='detail-title fs20 Bold'>
                            <div><img src={rocket} alt='' /></div>
                            <div>{t('mission-title')}</div>
                        </div>
                        <div className='detail-description fs18'>{t('mission-text')}</div>
                    </div>
                    <div className='horizental-line' />
                    <div className='detail-row'>
                        <div className='detail-title fs20 Bold'>
                            <div><img src={diamond} alt='' /></div>
                            <div>{t('values-title')}</div>
                        </div>
                        <div className='detail-description fs18'>{t('values-text')}</div>
                    </div>
                </div>
            </div>
            <OwlCarousel className='owl-theme owl-green' autoplay={true} autoplayTimeout={3000} autoplayHoverPause={true} items={1} loop margin={10} nav={false} dots={false}>
                {CarouselCaptions}
            </OwlCarousel>
        </div>

    )
}
export default Home