//ContentModal
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent } from '@mui/material';
import TextField from '@mui/material/TextField';
import axios from './api'
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useState } from 'react';

export default function ContentModal({ text, icon, id }) {
    const [open, setOpen] = React.useState(false);
    var data = new FormData();
    const [slug,setSlug]=useState('')
    const [titleEn,setTitleEn]=useState('')
    const [titleAr,setTitleAr]=useState('')
    const [valueEn,setValueEn]=useState('')
    const [valueAr,setValueAr]=useState('')

    const handleClickOpen = () => {
        data.append('id', id)
        if (text === 'Edit') {
            axios.getContentById(data).then((res) => {
                setOpen(true);
                setSlug(res.data.data.slug)
                setTitleEn(res.data.data.title_en)
                setTitleAr(res.data.data.title_ar)
                setValueEn(res.data.data.text_en)
                setValueAr(res.data.data.text_ar)
            })
        }
        else {
            setOpen(true)
        }
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleOnConfirm = ()=>{
        if(text==='Delete'){
            DeleteContent()
        }else if(text==='Edit'){
            EditContent()
        }else{
            AddContent()
        }
    }
    const AddContent = () => {
        data.append('slug', document.getElementById('Content-slug').value);
        data.append('title_en', document.getElementById('Content-title-en').value);
        data.append('title_ar', document.getElementById('Content-title-ar').value);
        data.append('text_en', document.getElementById('Content-text-en').value);
        data.append('text_ar', document.getElementById('Content-text-ar').value);
        axios.addContent(data).then((res) => {
            window.location.reload(true);
        }).catch(function (error) {
            console.log(error)
        });
        setOpen(false);
    };
    const EditContent = () => {
        data.append('id', id)
        data.append('slug', document.getElementById('Content-slug').value);
        data.append('title_en', document.getElementById('Content-title-en').value);
        data.append('title_ar', document.getElementById('Content-title-ar').value);
        data.append('text_en', document.getElementById('Content-text-en').value);
        data.append('text_ar', document.getElementById('Content-text-ar').value);
        axios.editContent(data).then((res) => {
            window.location.reload(true);
        }).catch(function (error) {
            console.log(error)
        });
        setOpen(false);
    };
    const DeleteContent = () => {
        console.log(id)
        data.append('id', id)
        axios.deleteContent(data).then((res) => {
            console.log(res)
            window.location.reload(true);
        }).catch(function (error) {
            console.log(error)
        });
        setOpen(false);
    };
    return (
        <div className='Buttontest-container'>
            <button className='fs16' onClick={handleClickOpen}>
                {text == 'Add' ? text : ''}<i className={icon == 'Edit' ? 'fa fa-pen' : icon == 'Delete' ? 'fa fa-trash' : ''}></i>
            </button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{text} Content</DialogTitle>
                <DialogContent >
                    {icon !== 'Delete' && <div className='dialog-content-container'>
                    <div className='dialog-row'>
                            <TextField
                                defaultValue={slug}
                                margin="dense"
                                id="Content-slug"
                                label="Slug"
                                type="email"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                        <div className='dialog-row'>
                            <TextField
                                defaultValue={titleEn}
                                margin="dense"
                                id="Content-title-en"
                                label="Title in English"
                                type="email"
                                fullWidth
                                variant="standard"
                            />
                            <TextField
                                margin="dense"
                                defaultValue={titleAr}
                                id="Content-title-ar"
                                label="Title in Arabic"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                        <div className='dialog-row'>
                            <TextareaAutosize 
                                id="Content-text-en" 
                                defaultValue={valueEn}
                                minRows={8} type='text' 
                                placeholder="Value in English" />
                        </div>
                        <div className='dialog-row'>
                            <TextareaAutosize 
                                id="Content-text-ar" 
                                defaultValue={valueAr}
                                minRows={8} type='text'
                                placeholder="Value in Arabic" />
                        </div>
                    </div>}
                    {icon == 'Delete' && <p>Are you sure you want to delete it?</p>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleOnConfirm}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
