import React, { useEffect, useState } from 'react'
import partnersImage from '../../assets/images/banner.png'
import './Partners.css'
import PartnerArea from '../../components/PartnerArea/PartnerArea'
import partnersData from '../../assets/data/partners'
import axios from './api'

const Partners = () => {
    const [partners, setPartners] = useState()
    useEffect(() => {
        axios.getPartners().then((res) => {
            setPartners(res.data.data);
            setTimeout(function () {
                if (window.location.hash !== '') {
                    const element = document.getElementById('partner' + window.location.hash.substring(1));
                    window.scrollTo(0, element.offsetTop)
                }
            }, 100)
        })
    }, [])
    const arr = partners?.map((partner, index) => {

        return (
            <PartnerArea
                key={index}
                name={partner.name_en}
                background={index % 2 === 0 ? '' : 'background-color'}
                title={'partner' + partner.id + 'name'}
                id={partner.id}
                website={partner.website}
                drs={partner.doctors}
            />
        )
    })
    return (
        <div className='partners'>
            <div className='image-layer page-image'><img className='partners-img' src={partnersImage} alt='partners'></img></div>
            <div className='partners-container'>
                {arr}
            </div>
        </div>
    )
}

export default Partners