//ContentModal
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent } from '@mui/material';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import axios from './api'
import { useState } from 'react';
export default function SettingModal({ text, icon, id }) {
    const [open, setOpen] = React.useState(false);
    var data = new FormData();
    const [field,setField]=useState('')
    const [valueEn,setValueEn]=useState('')
    const [valueAr,setValueAr]=useState('')

    const handleClickOpen = () => {
        data.append('id', id)
        if (text === 'Edit') {
            axios.getSettingById(data).then((res) => {
                var x= res.data.data.field
                setOpen(true);
                setField(res.data.data.field)
                setValueEn(res.data.data.value_en)
                setValueAr(res.data.data.value_ar)
            })
        }
        else {
            setOpen(true)
        }
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleOnConfirm = () => {
        if (text === 'Delete') {
            DeleteSetting()
        } else if (text === 'Edit') {
            EditSetting()
        } else {
            AddSetting()
        }
    }
    const AddSetting = () => {
        data.append('field', document.getElementById('Setting-field').value);
        data.append('value_en', document.getElementById('setting_en_value').value);
        data.append('value_ar', document.getElementById('setting_ar_value').value);
        axios.addSetting(data).then((res) => {
            window.location.reload(true);
        }).catch(function (error) {
            console.log(error)
        });
        setOpen(false);
    };
    const EditSetting = () => {
        data.append('id', id)
        data.append('field', document.getElementById('Setting-field').value);
        data.append('value_en', document.getElementById('setting_en_value').value);
        data.append('value_ar', document.getElementById('setting_ar_value').value);
        axios.editSetting(data).then((res) => {
            window.location.reload(true);
        }).catch(function (error) {
            console.log(error)
        });
        setOpen(false);
    };
    const DeleteSetting = () => {
        data.append('id', id)
        axios.deleteSetting(data).then((res) => {
            console.log(res)
            window.location.reload(true);
        }).catch(function (error) {
            console.log(error)
        });
        setOpen(false);
    };
    return (
        <div className='Buttontest-container'>
            <button className='fs16' onClick={handleClickOpen}>
                {text == 'Add' ? text : ''}<i className={icon == 'Edit' ? 'fa fa-pen' : icon == 'Delete' ? 'fa fa-trash' : ''}></i>
            </button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{text} Setting</DialogTitle>
                <DialogContent >
                    {icon !== 'Delete' && <div className='dialog-content-container'>
                        <div className='dialog-row'>
                            <TextField
                                defaultValue={field}
                                margin="dense"
                                id="Setting-field"
                                label="Field"
                                type="email"
                                fullWidth
                                variant="standard"
                                style={{
                                    minWidth: 350
                                }}
                            />
                        </div>
                        <div className='dialog-row'>
                            <TextareaAutosize 
                                id='setting_en_value' 
                                defaultValue={valueEn}
                                minRows={8} type='text' 
                                placeholder="Value in English" />
                        </div>
                        <div className='dialog-row'>
                            <TextareaAutosize 
                                id='setting_ar_value' 
                                defaultValue={valueAr}
                                minRows={8} type='text' 
                                placeholder="Value in Arabic" />
                        </div>
                    </div>}
                    {icon == 'Delete' && <p>Are you sure you want to delete it?</p>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleOnConfirm}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
