import React from "react";
import "./Treatments.css";
import "./arTreatments.css";
import Procedure from "../../components/Procedure/Procedure";
import procedureData from "../../assets/data/procedures";
import bannerImg from "../../assets/images/treatment-banner.png";
import data from "../../assets/data/treatments";
import medicaloffersDta from "../../assets/data/mediaclOffers";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HospitalCard from "../../components/HospitalCrad/HospitalCard";
import hospitalData from "../../assets/data/drSpecialty";
import axios from "./api";

const Treatments = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [treats, setTreatments] = useState();
  const [offer, setOffer] = useState([]);
  const [additionalCost, aetAdditinalCost] = useState(0);
  // const [specialtyCost, setSpecialtyCost] = useState(0);
  const [partners, setPartners] = useState([]);
  console.log("sexifon");
  // var totalCost = parseInt(specialtyCost) + parseInt(additionalCost);
  //------------------------------------------------------------
  useEffect(() => {
    var data = new FormData();
    data.append("treatment_id", params.offer);
    axios.getSpecialtiesByTreatment(data).then((res) => {
      setOffer(res.data.data);
      //console.log(res.data.data)
    });
    axios.getTreatments().then((res) => {
      //console.log(res)
      setTreatments(res.data.data);
    });
    axios.getpartnersbytreatment(data).then((res) => {
      //console.log(res.data.data)
      setPartners(res.data.data);
    });
  }, [params.offer]);
  //------------------------------------------------------------
  // const MedicalOffersHandle = (e) => {
  //   setSpecialtyCost(e.target.value);
  // };
  //------------------------------------------------------------
  const moffers = offer?.map((o, index) => {
    var x, background;
    index % 2 !== 0 ? (background = "grey-back") : (background = "");
    index === 0
      ? (x = "start-row")
      : index === offer.length - 1
      ? (x = "last-row")
      : (x = "middle-row");
    return (
      <tr key={index}>
        <td className={x + " fs18 offer-description " + background}>
          {t(o.name_en)}
        </td>
        {/* <td className={x + ' fs18 offer-cost'}>{o.price} €</td> */}
      </tr>
    );
  });
  //------------------------------------------------------------
  const hospitals = partners?.map((hospital, index) => {
    let num = index + 1;
    return (
      <div
        key={index}
        onClick={() => {
          navigate("/partners#" + hospital.partner_id);
        }}
      >
        <HospitalCard
          name={t("partner" + hospital.partner.id + "name")}
          number={num}
          drList={hospital.dr}
        />
      </div>
    );
  });
  //------------------------------------------------------------
  const procedures = procedureData.map((procedure, index) => {
    let counter = index + 1;
    return (
      <Procedure
        key={index}
        position={index === 0 ? "start" : ""}
        text={t("procedure-" + counter)}
        number={"0" + counter}
      />
    );
  });
  //------------------------------------------------------------
  const treatments = treats?.map((treatment, index) => {
    return (
      <option key={index} value={treatment.id}>
        {t("treatment" + treatment.id + "name")}
      </option>
    );
  });
  //------------------------------------------------------------
  const HandleChange = (e) => {
    navigate("/treatments/" + e.target.value);
  };
  //------------------------------------------------------------
  const AdditionalHandle = (e) => {
    if (e.target.checked === true) {
      var x = parseInt(e.target.value) + parseInt(additionalCost);
      aetAdditinalCost(x);
    } else {
      aetAdditinalCost(additionalCost - e.target.value);
    }
  };
  //------------------------------------------------------------
  return (
    <div>
      <div className="page-image image-layer">
        <img className="treatment-image" src={bannerImg} alt="" />
      </div>
      <div
        className={
          i18n.resolvedLanguage + " global-container treatment-page-container"
        }
      >
        <div className="fs38 Bold title">{t("procedures_title")}</div>
        <div className="procedures-container">{procedures}</div>
        <div className="fs38 Bold title">{t("package_title")}</div>
        <div className="treat-combo-box">
          <select
            onChange={HandleChange}
            id="treatment-title"
            className="Bold fs18 treatments-contact-input"
            defaultValue={params.offer}
          >
            {treatments}
          </select>
        </div>
        <div className="fs18 medical-offers">
          <p className="Bold">{t("select_phrase")}</p>
          <table className="offers">
            <tbody>{moffers}</tbody>
          </table>
        </div>
        <div className="fs22 hospitals-and-doctors">
          <p className="Bold">{t("hp_and_dr")}</p>
          {hospitals}
        </div>
        <div className="confirm">
          <div className="fs18 italic">{t("price_warning")}</div>
          <div className="request-button">
            <button
              onClick={() => {
                navigate("/contact");
              }}
              className="fs18 Bold"
            >
              {t("request_quotation")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Treatments;
