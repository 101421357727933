import axios from '../plugins/http.service';
const axiosFunction = {
    getSettings() {
        return axios.get("settings/get-settings");
    },
    getContents() {
        return axios.get("content/get-contents");
    },
    getTestimonials() {
        return axios.get("testimonial/get-testimonials");
    },
    getPartners() {
        return axios.get("partner/get-partners");
    },
    getTreatments() {
        return axios.get("treatment/get-treatments");
    },
    getDoctors() {
        return axios.get("doctor/get-doctors");
    },
    getSpecialties() {
        return axios.get("specialty/get-specialties");
    }
}
export default axiosFunction;