import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent } from '@mui/material';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import axios from './api'
import { useState } from 'react';

export default function TestimonialsModal({ text, icon, id }) {
    const [open, setOpen] = React.useState(false);
    var data = new FormData();
    const [name,setName]=useState('')
    const [tetimonialText,setText]=useState('')
    const [lang,setLang] = useState(1)
    const handleClickOpen = () => {
        data.append('id', id)
        if (text === 'Edit') {
            axios.getTestimonialById(data).then((res) => {
                setOpen(true);
                setName(res.data.data.name)
                setText(res.data.data.text)
                setLang(res.data.data.lang)
            })
        }
        else {
            setOpen(true)
        }
    };

    const handleClose = () => {
        setOpen(false);
    };
    
    const handleOnConfirm = ()=>{
        if(text==='Delete'){
            DeleteTestimonial()
        }else if(text==='Edit'){
            EditTestimonial()
        }else{
            AddTestimonial()
        }
    }
    const AddTestimonial = () => {
        data.append('name', document.getElementById('ByTestimonial').value);
        data.append('text', document.getElementById('textOfTestimonial').value);
        data.append('lang', lang);
        axios.addTestimonial(data).then((res) => {
            window.location.reload(true);
        }).catch(function (error) {
            console.log(error)
        });
        setOpen(false);
    };
    const EditTestimonial = () => {
        data.append('id', id)
        data.append('name', document.getElementById('ByTestimonial').value);
        data.append('text', document.getElementById('textOfTestimonial').value);
        data.append('lang', lang);

        axios.editTestimonial(data).then((res) => {
            window.location.reload(true);
        }).catch(function (error) {
            console.log(error)
        });
        setOpen(false);
    };
    const DeleteTestimonial= () => {
        console.log(id)
        data.append('id', id)
        axios.deleteTestimonial(data).then((res) => {
            console.log(res)
            window.location.reload(true);
        }).catch(function (error) {
            console.log(error)
        });
        setOpen(false);
    };
    const checklang = (e)=>{setLang(e.target.value)}
    return (
        <div className='Buttontest-container'>
            <button className='fs16' onClick={handleClickOpen}>
                {text == 'Add' ? text : ''}<i className={icon == 'Edit' ? 'fa fa-pen' : icon == 'Delete' ? 'fa fa-trash' : ''}></i>
            </button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{text} Testimonial</DialogTitle>
                <DialogContent>
                    {icon !== 'Delete' && <div>
                        <TextField
                            margin="dense"
                            defaultValue={tetimonialText}
                            id="textOfTestimonial"
                            label="Text"
                            type="email"
                            fullWidth
                            variant="standard"
                        />
                        <TextField
                            margin="dense"
                            defaultValue={name}
                            id="ByTestimonial"
                            label="By"
                            type="email"
                            fullWidth
                            variant="standard"
                        />
                        <FormControl variant="standard" sx={{ minWidth: '100%' }}>
                            <InputLabel id="testimonial-language-label">Choose The Language</InputLabel>
                            <Select
                                id={'language_of_testimonial'}
                                labelId='testimonial-language-label'
                                variant='standard'
                                sx={{ width: '100%', marginTop: '30px' }}
                                defaultValue={lang}
                                onChange={checklang}
                            >
                                <MenuItem value={1}>English</MenuItem>
                                <MenuItem value={2}>Arabic</MenuItem>
                            </Select>
                        </FormControl>

                    </div>}
                    {icon == 'Delete' && <p>Are you sure you want to delete it?</p>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleOnConfirm}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
