import * as React from 'react';
import data from '../../assets/data/treatments';
import './Admin.css'
import dr from '../../assets/images/Dr2.jpg'
import AdminDataGrid from '../../components/AdminDataGrid/AdminDataGrid';
import DoctorsModal from '../../components/Modal/doctors-modal';
import { useEffect } from 'react';
import { useState } from 'react';
import uploads_links from '../../plugins/shared-links';
import axios from './api'

export default function Doctors() {
    const Button = (params) => {
        return (
            <DoctorsModal text={params.field} icon={params.field} id={params.row.id}/>
        )
    }

    const [rows, setRows] = useState([])
    useEffect(() => {
        axios.getDoctors().then((res) => {
          //  setSettingsData(res.data.data)
          setRows(res.data.data)
        }).catch(function (error) {
            console.log(error)
        });
    },[])
    
    const columns = [
        {
            field: 'src',
            headerName: 'Image',
            renderCell: (params) => <img className='dr-image admin-treat-icon' src={uploads_links[1]+params.row.picture} alt=''/>,
            flex: 1,
        },
        {
            field: 'name_en',
            headerName: 'Name En',
            flex: 1,
        },
        {
            field: 'name_ar',
            headerName: 'Name Ar',
            flex: 1
        },
        {
            field: 'partner_en',
            headerName: 'Partner Name',
            flex: 1,
        },
        {
            field: 'position_en',
            headerName: 'Dr\'s Position En',
            flex: 1,
        },
        {
            field: 'position_ar',
            headerName: 'Dr\'s Position Ar',
            flex: 1,
        },
        {
            field: 'Edit',
            headerName: 'Edit',
            renderCell: Button
        },
        {
            field: 'Delete',
            headerName: 'Delete',
            renderCell: Button
        }
    ];

    return (
        <div style={{ height: 700, width: '100%' }}>
            <div className='Title-container'>
                <div className='fs38 Admin-title'>Doctors</div>
                <DoctorsModal text='Add' />
            </div>
            <AdminDataGrid columns={columns} rows={rows} />
        </div>
    )
}