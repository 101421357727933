//ContentModal
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent } from '@mui/material';
import TextField from '@mui/material/TextField';
import axios from './api'
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useState } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

export default function ContactModal({ text, icon, id }) {
    const [open, setOpen] = React.useState(false);
    var data = new FormData();
    const [fullname,setFullName]=useState('')
    const [email,setEmail]=useState('')
    const [mobile,setMobile]=useState('')
    const [contactTime,setContactTime]=useState('')
    const [message,setMessage]=useState('')
    const [file,setFile]=useState('')
    const [country,setCountry]=useState('mobile')
    const [ContactType,setContactType]=useState('mobile')

    const handleClickOpen = () => {
        data.append('id', id)
        if (text === 'Edit') {
            axios.getContactById(data).then((res) => {
                setOpen(true);
                setFullName(res.data.data.full_name)
                setEmail(res.data.data.email)
                setMobile(res.data.data.mobile)
                setContactTime(res.data.data.contact_time)
                setContactType(res.data.data.contact_type)
                setFile(res.data.data.file)
                setMessage(res.data.data.message)
                setCountry(res.data.data.country)
            })
        }
        else {
            setOpen(true)
        }
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleOnConfirm = ()=>{
        if(text==='Delete'){
            DeleteContact()
        }else if(text==='Edit'){
            EditContact()
        }else{
            AddContact()
        }
    }
    const AddContact = () => {
        data.append('full_name', document.getElementById('Contact-full-name').value);
        data.append('email', document.getElementById('Contact-email').value);
        data.append('mobile', document.getElementById('Contact-number').value);
        data.append('country', document.getElementById('Contact-country').value);
        data.append('contact_time', document.getElementById('Contact-time').value);
        data.append('contact_type', ContactType);
        data.append('file', document.querySelector('input[type=file]')['files'][0]);
        data.append('message', document.getElementById('Contact-message').value);
        axios.addContact(data).then((res) => {
            window.location.reload(true);
        }).catch(function (error) {
            console.log(error)
        });
        setOpen(false);
    };
    const EditContact = () => {
        data.append('id', id)
        data.append('full_name', document.getElementById('Contact-full-name').value);
        data.append('email', document.getElementById('Contact-email').value);
        data.append('mobile', document.getElementById('Contact-number').value);
        data.append('country', document.getElementById('Contact-country').value);
        data.append('contact_time', document.getElementById('Contact-time').value);
        data.append('contact_type', ContactType);
        data.append('file', document.querySelector('input[type=file]')['files'][0]);
        data.append('message', document.getElementById('Contact-message').value);
        axios.editContact(data).then((res) => {
            window.location.reload(true);
        }).catch(function (error) {
            console.log(error)
        });
        setOpen(false);
    };
    const DeleteContact = () => {
        console.log(id)
        data.append('id', id)
        axios.deleteContact(data).then((res) => {
            window.location.reload(true);
        }).catch(function (error) {
            console.log(error)
        });
        setOpen(false);
    };
    return (
        <div className='Buttontest-container'>
            <button className='fs16' onClick={handleClickOpen}>
                {text == 'Add' ? text : ''}<i className={icon == 'Edit' ? 'fa fa-pen' : icon == 'Delete' ? 'fa fa-trash' : ''}></i>
            </button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{text} Contact</DialogTitle>
                <DialogContent >
                    {icon !== 'Delete' && <div className='dialog-content-container'>
                    <div className='dialog-row'>
                            <TextField
                                defaultValue={fullname}
                                margin="dense"
                                id="Contact-full-name"
                                label="Full Name"
                                type="email"
                                fullWidth
                                variant="standard"
                            />
                            <TextField
                                defaultValue={country}
                                margin="dense"
                                id="Contact-country"
                                label="Country"
                                type="email"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                        <div className='dialog-row'>
                            <TextField
                                defaultValue={mobile}
                                margin="dense"
                                id="Contact-number"
                                label="Mobile Number"
                                type="email"
                                fullWidth
                                variant="standard"
                            />
                            <TextField
                                margin="dense"
                                defaultValue={email}
                                id="Contact-email"
                                label="Email"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                        <div className='dialog-row'>
                            <TextField
                                defaultValue={contactTime}
                                margin="dense"
                                id="Contact-time"
                                label="Contact Time"
                                type="email"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                        <div className='dialog-row'>
                            <TextareaAutosize 
                                id="Contact-message" 
                                defaultValue={message}
                                minRows={8} type='text' 
                                placeholder="message" />
                        </div>
                        <FormControl variant="standard" sx={{ minWidth: '100%' }}>
                            <InputLabel id="Contact-type-label">Choose The Contact Type</InputLabel>
                            <Select
                                id='choose-contact-type'
                                defaultValue={ContactType}
                                onChange= {(e)=>{setContactType(e.target.value)}}
                                labelId='Contact-type-label'
                                variant='standard'
                                sx={{ width: '100%', marginTop: '30px' }}
                            >
                                <MenuItem value={'mobile'}>Mobile</MenuItem>
                                <MenuItem value={'email'}>Email</MenuItem>
                            </Select>
                        </FormControl>
                        <input type='file'/>
                    </div>}
                    {icon == 'Delete' && <p>Are you sure you want to delete it?</p>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleOnConfirm}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
