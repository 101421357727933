import * as React from 'react';
import { useState, useEffect } from 'react' 
import './Admin.css'
import AdminDataGrid from '../../components/AdminDataGrid/AdminDataGrid';
import ContactModal from '../../components/Modal/contact-modal';
import axios from './api'
import uploads_links from '../../plugins/shared-links';
import { useNavigate } from 'react-router-dom';

export default function Contacts() {
    const navigate = useNavigate();
    const Button = (params) => {
        return (
            <ContactModal text={params.field} icon={params.field} id={params.row.id}/>
        )
    }
    const [rows, setRows] = useState([])
    useEffect(() => {
        axios.getContacts().then((res) => {
          //  setSettingsData(res.data.data)
          setRows(res.data.data)
        }).catch(function (error) {
            console.log(error)
        });
    }, []);
    const handleClick = (params)=>{
        window.open(uploads_links[2]+params.row.file,'_blank')
    }

    const columns = [
        {
            field: 'full_name',
            headerName: 'Full Name',
            flex: 1.5,
        },
        {
            field: 'country',
            headerName: 'Country',
            flex: 1,
        },
        {
            field: 'mobile',
            headerName: 'Mobile',
            flex: 1
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 2,
        },
        {
            field: 'contact_time',
            headerName: 'Contact Time',
            flex: 1
        },
        {
            field: 'contact_type',
            headerName: 'Contact Type',
            flex: 1
        },
        {
            field: 'message',
            headerName: 'Message',
            flex: 3,
        },
        {
            field: 'file',
            headerName: 'File',
            renderCell: (params)=><div>{params.row.file!==null && <button onClick={()=>{handleClick(params)}} className='file-button'><i className='fa fa-file'/></button>}</div>
        },
        {
            field: 'Edit',
            headerName: 'Edit',
            renderCell: Button
        },
        {
            field: 'Delete',
            headerName: 'Delete',
            renderCell: Button
        }
    ];
    
    return (
        <div style={{ height: 700, width: '100%' }}>
            <div className='Title-container'>
                <div className='fs38 Admin-title'>Contacts</div>
                <ContactModal text='Add' />
            </div>
            <AdminDataGrid columns={columns} rows={rows} />
        </div>
    );
}