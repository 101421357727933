import React from 'react'
import './Procedure.css'

const Procedure = ({position,text,number}) => {
    return (
        <div className='procedure'>
            {position!=='start' && <div className="union-line"><hr></hr></div>}
            <div className='procedure-border'>
                <div className='fs20 procedure-background'>
                    <div>{text}</div>
                </div>
                <div className="fs22 Bold procedure-number">
                    {number}
                </div>
            </div>
        </div>
    )
}

export default Procedure