import * as React from 'react';
import './Admin.css'
import AdminDataGrid from '../../components/AdminDataGrid/AdminDataGrid';
import SettingModal from '../../components/Modal/setting-modal';
import axios from './api'
import { useEffect } from 'react';
import { useState } from 'react';

export default function Settings() {
    const Button = (params) => {
        return (
            <SettingModal text={params.field} icon={params.field} id={params.row.id}/>
        )
    }
    const [rows, setRows] = useState([])
    useEffect(() => {
        axios.getSettings().then((res) => {
          //  setSettingsData(res.data.data)
          setRows(res.data.data)
        }).catch(function (error) {
            console.log(error)
        });
    }, []);
    const columns = [
        {
            field: 'field',
            headerName: 'Field',
            flex: 1,
        },
        {
            field: 'value_en',
            headerName: 'Value En',
            flex: 1,
        },
        {
            field: 'value_ar',
            headerName: 'Value Ar',
            flex: 1
        },
        {
            field: 'Edit',
            headerName: 'Edit',
            renderCell: Button
        },
        {
            field: 'Delete',
            headerName: 'Delete',
            renderCell: Button
        }
    ];
    return (
        <div style={{ height: 700, width: '100%' }}>
            <div className='Title-container'>
                <div className='fs38 Admin-title'>Settings</div>
                <SettingModal text='Add' />
            </div>
            <AdminDataGrid columns={columns} rows={rows} />
        </div>
    );
}