import React from 'react'
import './TreatmentCard.css'
import arrow from "../../assets/images/noun-arrow-2841741.svg"
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const TreatmentCard = ({ text, src, forward, linked }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <div className='treatment-option Bold '>
            <img className='expandableIcon pointer' src={src} onClick={(e) => { navigate('/treatments/' + forward) }} alt='noun surgery' />
            <div className='fs18'>{text}</div>
            {/* {linked==1 &&    <div className='fs16 treatmentForward'>
                <div onClick={()=>{}} className='go-To'>{t('know_more')}</div>
                <div><img src={arrow} alt='right arrow'/></div>
            </div>} */}
        </div>
    )
}

export default TreatmentCard