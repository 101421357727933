import React from 'react'
import './CarouselCaption.css'
import image from '../../assets/images/Group 45.svg'
import line from '../../assets/images/Line 30.svg'

const CarouselCaption = ({title,text,signiture}) => {
    return (
        <div className='item green-carousel'>
            <div className='carousel-caption'>
                <div className='fs38 carousel-caption-title'>
                    {title}
                </div>
                <img className='caption-icon' src={image} alt=''/>
                <div className='fs18 caption-text'>
                {text}
                </div>
                <div className='caption-signiture fs18 Bold'>
                    <div><img src={line}/></div>
                    <p>{signiture}</p>
                    <div><img src={line}/></div>
                </div>
            </div>
        </div>
    )
}

export default CarouselCaption