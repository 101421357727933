import axios from '../../plugins/http.service';
const axiosFunction = {
    getSettings() {
        return axios.get("settings/get-settings");
    },
    getContents() {
        return axios.get("content/get-contents");
    },
    getTestimonials() {
        return axios.get("testimonial/get-testimonials");
    },
    getPartners() {
        return axios.get("partner/get-partners");
    },
    getTreatments() {
        return axios.get("treatment/get-treatments");
    },
    getPartnerTreatments(id) {
        return axios.post("partner-treatment/get-partner-treatments",id);
    },
    getSpecialties() {
        return axios.post("specialty/get-specialties");
    },
    getDoctors() {
        return axios.post("doctor/get-doctors");
    },
    getContacts() {
        return axios.post("contact/get-contacts");
    }
}
export default axiosFunction;