import React from "react"
const procedureData = [
    {
        text:"Estimate your  package in 30 seconds "
    },
    {
        text:"Request your free Quotation within 24 hours "
    },
    {
        text:"Reserve and Arrange your flight"
    },
    {
        text:"Get your treatment and return back well & safe "
    },
    {
        text:"We keep following up with you"
    }
]

export default procedureData