import './App.css';
import Webrouter from './router/router';
import { ar, en } from './languages/language'
import { useEffect, useState } from 'react'
import data from './views/home/testimonial-data';
import UserContext from "./context/userContext";

import axios from './layout/api';
import { useTranslation } from 'react-i18next';
function App() {
    const [loaded, setLoaded] = useState(false);
    const [socialMedia, setSocialMedia] = useState(false);
    const { i18n } = useTranslation();
    useEffect(() => {
        let counter = 0;
        axios.getSettings().then((res) => {
            counter++;
            let tmpSocialMedia = {}
            res.data.data.forEach(element => {
                ar[element.field] = element.value_ar;
                en[element.field] = element.value_en;
                if (element.field === 'facebook') {
                    tmpSocialMedia['facebook'] = element
                }
                if (element.field === 'instagram') {
                    tmpSocialMedia['instagram'] = element
                }
                if (counter == 6) {
                    setLoaded(true)
                }
            });
            setSocialMedia({ ...tmpSocialMedia });
        })
        axios.getContents().then((res) => {
            counter++;
            res.data.data.forEach(element => {
                ar[element.slug + '-title'] = element.title_ar;
                en[element.slug + '-title'] = element.title_en;
                ar[element.slug + '-text'] = element.text_ar;
                en[element.slug + '-text'] = element.text_en;
            });
            if (counter == 6) {
                setLoaded(true)
            }
        })
        axios.getTestimonials().then((res) => {
            //setSettingsData(res.data.data)
            counter++;
            data['ar'] = res.data.data.filter(
                (element) => {
                    return element.lang === 2
                })
            data['en'] = res.data.data.filter(
                (element) => {
                    return element.lang === 1
                })
            if (counter == 6) {
                setLoaded(true)
            }
        })
        axios.getPartners().then((res) => {
            counter++;
            res.data.data.forEach(element => {
                ar['partner' + element.id + 'name'] = element.name_ar;
                en['partner' + element.id + 'name'] = element.name_en;
                ar['partner' + element.id + 'description'] = element.description_ar;
                en['partner' + element.id + 'description'] = element.description_en;
            });
            if (counter == 6) {
                setLoaded(true)
            }
        })
        axios.getTreatments().then((res) => {
            counter++;
            res.data.data.forEach(element => {
                ar['treatment' + element.id + 'name'] = element.name_ar;
                en['treatment' + element.id + 'name'] = element.name_en;
            });
            if (counter == 6) {
                setLoaded(true)
            }
        })
        axios.getDoctors().then((res) => {
            counter++;
            res.data.data.forEach(element => {
                ar['doctor' + element.id + 'name'] = element.name_ar;
                en['doctor' + element.id + 'name'] = element.name_en;
                ar['doctor' + element.id + 'position'] = element.position_ar;
                en['doctor' + element.id + 'position'] = element.position_en
            });
            if (counter == 6) {
                setLoaded(true)
            }
        })
        axios.getSpecialties().then((res) => {
            counter++;
            res.data.data.forEach(element => {
                ar[element.name_en] = element.name_ar;
                en[element.name_en] = element.name_en;
            });
            console.log(en)
            if (counter == 6) {
                setLoaded(true)
            }
        })
    }, [])
    return (
        <div className="App">

            {loaded &&
                <UserContext.Provider value={{ socialMedia }}>
                    <Webrouter /></UserContext.Provider>}
        </div>
    );
}

export default App;
