import React from 'react'
import './HospitalCard.css'
import { useState } from 'react'

const HospitalCard = ({ number, name }) => {
    return (
        <div className='hospital-container pointer'>
            <div id={name} className='hospital'>
                <div className="Bold hospital-number">0{number}</div>
                <div className='Bold hospital-name'>{name} </div>
            </div>
        </div>
    )
}

export default HospitalCard