import axios from '../../plugins/http.service';
const axiosFunction = {
    getTreatments() {
        return axios.get("treatment/get-treatments");
    },
    getSpecialtiesByTreatment(data) {
        return axios.post("specialty/get-specialties-by-treatment",data);
    },
    getpartnersbytreatment(data) {
        return axios.post("partner-treatment/get-partners-by-treatment",data);
    }
}
export default axiosFunction;