import React from 'react'
import './AdditionalServices.css'
import Tooltip from '@mui/material/Tooltip';

const AdditionalService = ({ src, text, tootltipText }) => {

    return (
        <Tooltip title={tootltipText} placement="top">

            <div className='additional-service'>
                <div className='service-icon-border'>
                    <img className='service-icon' src={src} alt={text} />
                </div>
                <div className='fs20 Bold'>
                    {text}
                </div>
            </div>
        </Tooltip>
    )
}

export default AdditionalService