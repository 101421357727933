import * as React from 'react';
import './Admin.css'
import SpecialtyModal from '../../components/Modal/specialty-modal';
import AdminDataGrid from '../../components/AdminDataGrid/AdminDataGrid';
import { useEffect } from 'react';
import axios from './api'

export default function Specialties() {

    const [ treatments, setTreatments ] = React.useState()
    const [rows,setRows]=React.useState([])
    useEffect(()=>{
        axios.getTreatments().then((res) => {
            setTreatments(res.data.data)
        })
        axios.getSpecialties().then((res) => {
            setRows(res.data.data)
        })
    },[])
    
    const Button = (params) => {
        return (
            <SpecialtyModal id={params.row.id} treatments = {treatments} text={params.field} icon={params.field} />
        )
    }
    const columns = [
        {
            field: 'name_en',
            headerName: 'Name En',
            flex: 1,
        },
        {
            field: 'name_ar',
            headerName: 'Name Ar',
            flex: 1
        },
        {
            field: 'price',
            headerName: 'Price',
            flex: 1
        },
        {
            field: 'Edit',
            headerName: 'Edit',
            renderCell: Button
        },
        {
            field: 'Delete',
            headerName: 'Delete',
            renderCell: Button
        }
    ];

    return (
        <div style={{ height: 700, width: '100%' }}>
            <div className='Title-container'>
                <div className='fs38 Admin-title'>Specialties</div>
                <SpecialtyModal treatments = {treatments} text='Add' />
            </div>
            <AdminDataGrid columns={columns} rows={rows} />
        </div>
    );
}