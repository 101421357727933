import React, { useEffect } from 'react'
import './PartnerArea.css'
import './arPartnerAre.css'
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import uploads_links from '../../plugins/shared-links';
const PartnerArea = ({ background, title, drs, id, website, name }) => {
    const code = name.replaceAll(' ', '').toLowerCase();
    const [pictures, setPicture] = useState([]);
    useEffect(() => {
        for (var i = 1; i < 4; i++) {
            try {
                let tmp = pictures;
                tmp.push(require('../../assets/images/' + code + '-' + i + '.jpg'))
                setPicture(tmp)
            }
            catch (e) {
            }
        }


    }, [])
    const { t, i18n } = useTranslation();
    const [details, setDetails] = useState()
    useEffect(() => {
        setDetails(t('partner' + id + 'description').split(/\r?\n/))
    }, [i18n.language])
    const arr = details?.map((detail, index) => {
        return (
            <li key={index}>{detail}</li>
        )
    })
    const drsList = drs?.map((dr, index) => {
        return (
            <div key={index} className='dr-card'>
                <div><img src={uploads_links[1] + dr.picture} alt=''></img></div>
                <div className='fs18'>{t('doctor' + dr.id + 'name')}</div>
                <div className='dr-specialty Bold fs16'>{t('doctor' + dr.id + 'position')}</div>
            </div>
        )
    })
    return (
        <div className={background} id={'partner' + id}>
            <div className={i18n.resolvedLanguage + ' partner-area global-container'}>
                <div className='partner-name fs38'>
                    <div>{t(title)}</div>
                    <div className='partner-website'><button onClick={() => { window.open(website) }} className='fs18 Bold'>{t('partner_web')}</button></div>
                </div>
                <div className='hospitalPicture '>
                    {pictures.map((element) => {
                        return <img src={element}></img>
                    })}

                </div>
                <div className='partner-details fs18'>
                    <ul>
                        {arr}
                        {/* <li>{t('partner'+id+'description')}</li> */}
                    </ul>
                </div>
                <div className='drs-container'>
                    {drsList}
                </div>

            </div>

        </div>
    )
}

export default PartnerArea