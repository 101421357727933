const ar = {
  know_more: 'اعرف المزيد',
  homePageImg01_1: 'فرصتك للعلاج!',
  homePageImg01_2: '',
  homePageImg02_1: 'العناية بالقلب',
  homePageImg02_2: '',
  homePageImg03_1: 'علاج الشلل الدماغي ',
  homePageImg03_2: 'والتشنجات غير الإرادية',
  homePageImg04_1: 'زراعة الشعر',
  homePageImg04_2: '',
  homePageImg05_1: 'تجميل الأسنان',
  homePageImg05_2: '',
  homePageImg06_1: 'تصحيح النظر',
  homePageImg06_2: '',
  homePageBannerBtn01: "احجز علاجاتنا",
  hotline: 'الخط الساخن',
  whatsapp_number: 'واتساب',
  contact_name: 'الاسم الكامل (كما في جواز السفر)',
  contact_country: 'الدولة',
  contact_phone: 'رقم الهاتف المحمول',
  contact_email: 'البريد الإلكتروني',
  contact_time: 'الوقت المفضل للاتصال',
  contact_type: 'الوسيلة المفضلة للتواصل',
  bymobile: 'عن طريق الهاتف المحمول',
  byemail: 'عن طريق البريد الإلكتروني',
  contact_message: 'الاستفسار أو الرسالة',
  procedures_title: 'كيف نعمل؟',
  package_title: 'العلاجات والجراحات',
  hp_and_dr: 'الكادر الطبي المقترح',
  total_price: 'التكلفة الإجمالية المقدرة',
  price_warning: "تقدّم الأسعار بناءًا على رأي الطبيب في الإجراء الطبي المناسب بعد دراسة الملف الطبي الخاص بك، لذا نرجو التواصل معنا او تعبئة الاستمارة في الصفحة التالية",
  request_quotation: 'تواصل معنا للحصول على معلومات إضافية وعرض أسعار رسمي',
  submit_btn: 'ارسال',
  contact_title: 'تواصل معنا',
  how_to_find_us: 'كيف تجدنا؟',
  partner_web: 'زيارة الموقع',
  upload: 'رفع ملف',
  select_phrase: ':اختر اختصاصا من القائمة اعلاه كي ترى قائمة بالمستشفيات و الاطباء التي ننصح بهم',
  testimonials: 'المراجعات والشهادات',
};
const en = {
  know_more: 'Know More',
  homePageImg01_1: "Your Opportunity",
  homePageImg01_2: 'Of Healing!',
  homePageImg02_1: 'Cardiovascular Care',
  homePageImg02_2: '',
  homePageImg03_1: 'Cerebral Palsy ',
  homePageImg03_2: 'Spasticity Rehabilitation',
  homePageImg04_1: 'Hair Loss Treatment',
  homePageImg04_2: '',
  homePageImg05_1: 'Hollywood smile',
  homePageImg05_2: '',
  homePageImg06_1: 'Vision Correction',
  homePageImg06_2: '',
  homePageBannerBtn01: 'Book Our Treatments',
  hotline: 'Hotline',
  whatsapp_number: 'Whatsapp',
  contact_name: 'Full Name (as on the passport)',
  contact_country: 'Country',
  contact_phone: 'Mobile Number',
  contact_email: 'Email',
  contact_time: 'Preferred Time To Contact',
  contact_type: 'Preffered way of contact',
  bymobile: 'By Mobile',
  byemail: 'By Email',
  contact_message: 'Inquiry Or Message',
  procedures_title: 'Procedures',
  package_title: 'Treatments & Surgeries',
  hp_and_dr: 'Hospitals and Doctors List',
  total_price: 'Total Estimated Cost',
  price_warning: `The prices will be given after submitting your medical file and consulting the doctors, to do so, kindly proceed by filling the form`,
  request_quotation: 'Request Your Free Quotation',
  submit_btn: 'Submit',
  contact_title: 'Contact Us',
  how_to_find_us: 'How to Find Us?',
  partner_web: 'Visit Website',
  upload: 'Upload File',
  select_phrase: 'Select a specialty from the list below in order to have our recommended hospitals and doctors.',
  testimonials: 'Testimonials'
};
export { ar, en };