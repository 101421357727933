import React from 'react'
import Logo from '../assets/images/Logo.svg'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { I18nContext, useTranslation } from 'react-i18next'
import axios from './api'
import wp from '../assets/images/whatsapp.svg'
import { Link } from "react-router-dom";
import { useContext } from 'react'
import UserContext from '../context/userContext'
const Header = () => {

    const { t, i18n } = useTranslation();
    const [show, setShow] = useState(true);
    const [lng, setLng] = useState('Arabic');
    const navigate = useNavigate();
    const Activate = (e) => {
        HeaderToogle()
        var target = e.target.id;
        var ele1 = document.getElementsByClassName("header-active-item");
        var ele2 = document.getElementById(target);
        ele1[0].classList.remove("header-active-item");
        ele2.classList.add("header-active-item");
        if (target === 'home') {
            navigate('/')
        } else if (target === 'treatments') {
            navigate('/treatments/1')
        } else {
            navigate('/' + target)
        }
    }
    const TranslateLanguages = () => {
        if (lng === 'Arabic') {
            i18n.changeLanguage('ar');
            setLng('English')

        } else {
            i18n.changeLanguage('en');
            setLng('Arabic')
        }
    }

    useEffect(() => {

    }, []);
    const HeaderToogle = () => {
        var element = document.getElementById('menu-container');
        if (show == true)
            element.classList.remove("collapse");
        else
            element.classList.add("collapse");
        setShow(!show)
    }
    return (
        <nav id='header' className='global-container header-container'>
            <div id="logo-container" className={i18n.resolvedLanguage + ' logo-container'}>
                <div id='logo' className='logo'>
                    <Link to="/"><img className='logo-image' src={Logo} alt='Logo'></img></Link>
                </div>
                <div onClick={HeaderToogle} className='header-icon'><button className='translation-button'><i className='fa fa-bars'></i></button></div>
            </div>
            <div id='menu-container' className='collapse menu-conainer'>
                <ul className='menu-list' id='menu'>
                    <li className='fs16 header-active-item menu-item' onClick={Activate} id='home'>{t('home')}</li>
                    <li className='fs16 menu-item' onClick={Activate} id='treatments'>{t('treatments')}</li>
                    <li className='fs16 menu-item' onClick={Activate} id='partners'>{t('partners')}</li>
                    <li className='fs16 menu-item' onClick={Activate} id='contact'>{t('contactUs')}</li>
                    <li><button onClick={TranslateLanguages} className='menu-item translation-button'>{lng}</button></li>
                </ul>
            </div>
        </nav>
    )
}
const Footer = () => {
    const { socialMedia } = useContext(UserContext);
    const { t, i18n } = useTranslation();
    const [whatsappNumber, setWhatsappNumber] = useState('');
    useEffect(() => {
        let tmp = t('whatsapp');
        tmp = tmp.replaceAll(' ', '');
        tmp = tmp.replaceAll('00', '');

        setWhatsappNumber(tmp);
    }, [])
    return (
        <div className='footer-background'>
            <div className='global-container footer-container'>
                <div className='footer-element'>
                    <div>
                        <p className='fs18'><i onClick={() => { window.open(socialMedia['facebook'].value_en) }} className="fa fa-facebook"></i> | <i onClick={() => { window.open(socialMedia['instagram'].value_en) }} className="fa fa-instagram"></i></p>
                        <p className='fs18'>{t('copy-right')}</p>
                    </div>
                </div>
                <div className='vertical-line' ></div>
                <div className='footer-element middle-footer'>
                    <ul className='footer-list'>
                        <li className='fs18'><Link to="/">{t('home')}</Link></li>
                        <li className='fs18'><Link to="/treatments/1">{t('treatments')}</Link></li>
                        <li className='fs18'><Link to="/partners">{t('partners')}</Link></li>
                        <li className='fs18'><Link to="/contact">{t('contactUs')}</Link></li>
                    </ul>
                </div>
                <div className='vertical-line' />
                <div className='footer-element end-footer'>
                    <ul className='footer-list'>
                        <div><li className='fs18'>{t('address')}</li></div>
                        <div><hr></hr></div>
                        <div><li className='fs18'><a href="mailto:{t('email')}">{t('email')}</a></li></div>
                        <div><hr></hr></div>
                        <div><li className='fs18'><a href="tel:{t('phone')}">{t('phone')}</a></li></div>
                    </ul>
                </div>
                <img onClick={() => { window.open(`https://wa.me/${whatsappNumber}/`) }} className='whatsapp' src={wp} alt='' />
            </div>
        </div>
    )
}

const MainLayout = ({ children }) => {
    const { i18n } = useTranslation();
    return (
        <div className={i18n.resolvedLanguage}>
            <Header />
            {children}
            <Footer />
        </div>
    )
}

export default MainLayout