import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import './AdminDataGrid.css'

const AdminDataGrid = ({columns,rows}) => {
    return (
        <div style={{ height: 700, width: '100%' }}>
            <DataGrid
                getRowHeight={() => 'auto'}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                
                columns={columns}
                rows={rows}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
            />
        </div>
    )
}

export default AdminDataGrid