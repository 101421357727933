import * as React from 'react';
import { useState, useEffect } from 'react' 
import './Admin.css'
import AdminDataGrid from '../../components/AdminDataGrid/AdminDataGrid';
import ContentModal from '../../components/Modal/content-modal';
import axios from './api'

export default function Contents() {
    const Button = (params) => {
        return (
            <ContentModal text={params.field} icon={params.field} id={params.row.id}/>
        )
    }
    const [rows, setRows] = useState([])
    useEffect(() => {
        axios.getContents().then((res) => {
          //  setSettingsData(res.data.data)
          setRows(res.data.data)
        }).catch(function (error) {
            console.log(error)
        });
    }, []);
    const columns = [
        {
            field: 'slug',
            headerName: 'Slug',
            flex: 1,
        },
        {
            field: 'title_en',
            headerName: 'Title En',
            flex: 1,
        },
        {
            field: 'title_ar',
            headerName: 'Title Ar',
            flex: 1
        },
        {
            field: 'text_en',
            headerName: 'Text En',
            flex: 2,
        },
        {
            field: 'text_ar',
            headerName: 'Text Ar',
            flex: 2
        },
        {
            field: 'Edit',
            headerName: 'Edit',
            renderCell: Button
        },
        {
            field: 'Delete',
            headerName: 'Delete',
            renderCell: Button
        }
    ];
    
    return (
        <div style={{ height: 700, width: '100%' }}>
            <div className='Title-container'>
                <div className='fs38 Admin-title'>Contents</div>
                <ContentModal text='Add' />
            </div>
            <AdminDataGrid columns={columns} rows={rows} />
        </div>
    );
}