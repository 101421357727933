import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent } from '@mui/material';
import TextField from '@mui/material/TextField';
import './modals.css'
import axios from './api'
import { useState } from 'react';
import uploads_links from '../../plugins/shared-links';

export default function TreatmentsModal({ text, icon, id }) {
    const [open, setOpen] = React.useState(false);
    const [src, setSrc] = React.useState('');
    var data = new FormData();
    const [file,setFile]=useState('')
    const [nameEn,setNameEn]=useState('')
    const [nameAr,setNameAr]=useState('')

    const handleClickOpen = () => {
        data.append('id', id)
        if (text === 'Edit') {
            axios.getTreatmentById(data).then((res) => {
                setOpen(true);
                setFile(res.data.data.icon)
                setSrc(uploads_links[0]+res.data.data.icon)
                setNameAr(res.data.data.name_ar)
                setNameEn(res.data.data.name_en)
            })
        }
        else {
            setOpen(true)
        }
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleOnConfirm = ()=>{
        if(text==='Delete'){
            DeleteTreatment()
        }else if(text==='Edit'){
            EditTreatment()
        }else{
            AddTreatment()
        }
    }
    const AddTreatment = () => {
        data.append('icon', document.querySelector('input[type=file]')['files'][0]);
        data.append('name_en', document.getElementById('treatment-name-en').value);
        data.append('name_ar', document.getElementById('treatment-name-ar').value);
        axios.addTreatment(data).then((res) => {
            window.location.reload(true);
            // console.log(res.data.data)
            
        })
        setOpen(false);
    };
    const EditTreatment = () => {
        data.append('id', id)
        data.append('icon', document.querySelector('input[type=file]')['files'][0]);
        data.append('name_en', document.getElementById('treatment-name-en').value);
        data.append('name_ar', document.getElementById('treatment-name-ar').value);
        axios.editTreatment(data).then((res) => {
            window.location.reload(true);
            // console.log(res.data.data)
            
        }).catch(function (error) {
            console.log(error)
        });
        setOpen(false);
    };
    const DeleteTreatment = () => {
        data.append('id', id)
        axios.deleteTreatment(data).then((res) => {
            console.log(res)
            window.location.reload(true);
        }).catch(function (error) {
            console.log(error)
        });
        setOpen(false);
    };
    function imageUploaded() {
        var base64String
        var file = document.querySelector(
            'input[type=file]')['files'][0];
      
        var reader = new FileReader();
          
        reader.onload = function () {
            base64String = reader.result 
            setSrc(base64String)
        }
        reader.readAsDataURL(file);
    }
    return (
        <div className='Buttontest-container'>
            <button className='fs16' onClick={handleClickOpen}>
                {text == 'Add' ? text : ''}<i className={icon == 'Edit' ? 'fa fa-pen' : icon == 'Delete' ? 'fa fa-trash' : ''}></i>
            </button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{text} Partner</DialogTitle>
                <DialogContent>
                    {icon !== 'Delete' && <div className='dialog-content-container'>
                        <div className='treatment-image-modal'><img src={src} alt=''/></div>
                        <input onChange={imageUploaded} id='treatment-icon' type='file' accept="image" />
                        <div className='dialog-row'>
                            <TextField
                                margin="dense"
                                defaultValue={nameEn}
                                id="treatment-name-en"
                                label="Name in English"
                                type="email"
                                fullWidth
                                variant="standard"
                            />
                            <TextField
                                margin="dense"
                                defaultValue={nameAr}
                                id="treatment-name-ar"
                                label="Name in Arabic"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                    </div>}
                    {icon == 'Delete' && <p>Are you sure you want to delete it?</p>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleOnConfirm}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}