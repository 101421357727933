import * as React from 'react';
import captionsData from '../../assets/data/captions'
import './Admin.css'
import axios from './api'
import TestimonialsModal from '../../components/Modal/testimonial-modal';
import AdminDataGrid from '../../components/AdminDataGrid/AdminDataGrid';
import { useEffect, useState } from 'react';

export default function Testimonial() {
    const Button = (params) => {
        return (
            <TestimonialsModal text={params.field} icon={params.field} id={params.row.id} />
        )
    }
    const [rows, setRows] = useState([])
    useEffect(() => {
        axios.getTestimonials().then((res) => {
          //  setSettingsData(res.data.data)
          setRows(res.data.data)
        }).catch(function (error) {
            console.log(error)
        });
    }, []);
    const columns = [
        {
            field: 'text',
            headerName: 'Testimonial',
            flex: 3,
        },
        {
            field: 'name',
            headerName: 'By',
            flex: 1
        },
        {
            field: 'lang',
            headerName: 'Language',
            flex: 1
        },
        {
            field: 'Edit',
            headerName: 'Edit',
            renderCell: Button
        },
        {
            field: 'Delete',
            headerName: 'Delete',
            renderCell: Button
        }
    ];

    return (
        <div style={{ height: 700, width: '100%' }}>
            <div className='Title-container'>
                <div className='fs38 Admin-title'>Testimonials</div>
                <TestimonialsModal text='Add' />
            </div>
            <AdminDataGrid columns={columns} rows={rows} />
        </div>
    );
}