import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent } from '@mui/material';
import TextField from '@mui/material/TextField';
import './modals.css'
import axios from './api'
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useState } from 'react';

export default function PartnersModal({ text, icon, id }) {
    const [open, setOpen] = React.useState(false);
    var data = new FormData();
    const [name_en,setNameEn]=useState('')
    const [name_ar,setNameAr]=useState('')
    const [descriptionEn,setDescriptionEn]=useState('')
    const [descriptionAr,setDescriptionAr]=useState('')
    const [website,setWebsite]=useState('')

    const handleClickOpen = () => {
        data.append('id', id)
        if (text === 'Edit') {
            axios.getPartnerById(data).then((res) => {
                setOpen(true);
                setNameAr(res.data.data.name_ar)
                setNameEn(res.data.data.name_en)
                setDescriptionEn(res.data.data.description_en)
                setDescriptionAr(res.data.data.description_ar)
                setWebsite(res.data.data.website)
            })
        }
        else {
            setOpen(true)
        }
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleOnConfirm = ()=>{
        if(text==='Delete'){
            DeletePartner()
        }else if(text==='Edit'){
            EditPartner()
        }else{
            AddPartner()
        }
    }
    const AddPartner = () => {
        data.append('name_en', document.getElementById('partner-name-en').value);
        data.append('name_ar', document.getElementById('partner-name-ar').value);
        data.append('description_en', document.getElementById('partner-description-en').value);
        data.append('description_ar', document.getElementById('partner-description-ar').value);
        data.append('website', document.getElementById('partner-website').value);
        axios.addPartner(data).then((res) => {
            window.location.reload(true);
        }).catch(function (error) {
            console.log(error)
        });
        setOpen(false);
    };
    const EditPartner = () => {
        data.append('id', id)
        data.append('name_en', document.getElementById('partner-name-en').value);
        data.append('name_ar', document.getElementById('partner-name-ar').value);
        data.append('description_en', document.getElementById('partner-description-en').value);
        data.append('description_ar', document.getElementById('partner-description-ar').value);
        data.append('website', document.getElementById('partner-website').value);
        axios.editPartner(data).then((res) => {
            window.location.reload(true);
        }).catch(function (error) {
            console.log(error)
        });
        setOpen(false);
    };
    const DeletePartner = () => {
        console.log(id)
        data.append('id', id)
        axios.deletePartner(data).then((res) => {
            console.log(res)
            window.location.reload(true);
        }).catch(function (error) {
            console.log(error)
        });
        setOpen(false);
    };

    return (
        <div className='Buttontest-container'>
            <button className='fs16' onClick={handleClickOpen}>
                {text == 'Add' ? text : ''}<i className={icon == 'Edit' ? 'fa fa-pen' : icon == 'Delete' ? 'fa fa-trash' : ''}></i>
            </button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{text} Partner</DialogTitle>
                <DialogContent>
                    {icon !== 'Delete' && <div className='dialog-content-container'>
                        <div className='dialog-row'>
                            <TextField
                                margin="dense"
                                defaultValue={name_en}
                                id="partner-name-en"
                                label="Name in English"
                                type="email"
                                fullWidth
                                variant="standard"
                            />
                            <TextField
                                margin="dense"
                                defaultValue={name_ar}
                                id="partner-name-ar"
                                label="Name in Arabic"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                        <div className='dialog-row'>
                            <TextareaAutosize 
                                minRows={8} 
                                defaultValue={descriptionEn}
                                type='text'
                                id="partner-description-en"
                                placeholder="Description in English" />
                        </div>
                        <div className='dialog-row'>
                            <TextareaAutosize 
                                minRows={8} 
                                defaultValue={descriptionAr}
                                type='text' 
                                id="partner-description-ar"
                                placeholder='Description in Arabic' />
                        </div>
                        <div className="dialog-row">
                        <TextField
                                margin="dense"
                                defaultValue={website}
                                id="partner-website"
                                label="Website"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                    </div>}
                    {icon == 'Delete' && <p>Are you sure you want to delete it?</p>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleOnConfirm}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
