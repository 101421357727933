import React from "react"
import image1 from "../images/noun-flight-1968943.svg"
import image2 from "../images/noun-accommodation-3386732.svg"
import image3 from "../images/noun-transportation-2222161.svg"
import image4 from "../images/noun-map-2064292.svg"
const servicesData = [
    {
        text: "flights-title",
        src: image1,
        forward: "",
        tootltipTextar: `نقدم تذاكر سفر بأسعار
        مميزة وتنافسية`,
        tootltipTexten: `We provide flight
        tickets with the
        best prices`,

    },
    {
        text: "accommodation-title",
        src: image2,
        forward: "",
        tootltipTextar: `نساعد في اختيار مكان
        اإلقامة من فندق أو شقة
        أو استديو`,
        tootltipTexten: `We help you find
        the suitable hotel –
        apartment – studio`,

    },
    {
        text: "transportation-title",
        src: image3,
        forward: "",
        tootltipTextar: `نؤ ّمن المواصالت من وإلى
        المطار – المشفى – مكان
        اإلقامة`,
        tootltipTexten: `We arrange your
        transportation from
        and to the Airport –
        Hospital - Stay`,

    },
    {
        text: "guided-tours-title",
        src: image4,
        forward: "",
        tootltipTextar: `نقدم اقتراحات وحجوزات
        للمرضى الراغبين بالقيام
        بالسياحة والترويح عن`,
        tootltipTexten: `We suggest guided
        tours and trips for
        patients seeking
        tourism and wellness`,

    }
]

export default servicesData