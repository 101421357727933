import React, { useContext, useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useNavigate,
} from "react-router-dom";
import MainLayout from '../layout/mainLayout';
import Home from '../views/home/home';
import Partners from '../views/partners/Partners';
import ContactUs from '../views/ContactUs/ContactUs';
import Treatments from '../views/Treatments/Treatments';
import MiniDrawer from '../layout/AdminLayout';
import AdminLogin from '../views/AdminLogIn/AdminLogIn';
import { userContext, UserProvider } from '../context/userContext';
import Testimonial from '../views/Admin/testimonial';
import AdminPartners from '../views/Admin/partner';
import AdminTreatments from '../views/Admin/treatment';
import Doctors from '../views/Admin/doctor';
import Specialties from '../views/Admin/specialty';
import Contents from '../views/Admin/content';
import Settings from '../views/Admin/setting';
import Contacts from '../views/Admin/contacts';
function Webrouter() {
    return (
        <Router>
                <Routes>
                    <Route path="/" element={<MainLayout><Home /></MainLayout>} />
                    <Route path="/contact" element={<MainLayout><ContactUs /></MainLayout>} />
                    <Route path="/treatments/:offer" element={<MainLayout><Treatments /></MainLayout>} />
                    <Route path="/partners" element={<MainLayout><Partners /></MainLayout>} />
                    <Route path="/admin" element={<AdminLogin />} />
                    <Route path="/admin/treatments" element={<MiniDrawer><UserPage children={<AdminTreatments/>} /></MiniDrawer>} />
                    <Route path="/admin/specialties" element={<MiniDrawer><UserPage children={<Specialties/>} /></MiniDrawer>} />
                    <Route path="/admin/partners" element={<MiniDrawer><UserPage children={<AdminPartners/>} /></MiniDrawer>} />
                    <Route path="/admin/doctors" element={<MiniDrawer><UserPage children={<Doctors/>} /></MiniDrawer>} />
                    <Route path="/admin/testimonials" element={<MiniDrawer><UserPage children={<Testimonial/>} /></MiniDrawer>} />
                    <Route path="/admin/content" element={<MiniDrawer><UserPage children={<Contents/>} /></MiniDrawer>} />
                    <Route path="/admin/settings" element={<MiniDrawer><UserPage children={<Settings/>} /></MiniDrawer>} />
                    <Route path="/admin/contacts" element={<MiniDrawer><UserPage children={<Contacts/>} /></MiniDrawer>} />
            
                </Routes>
            </Router>
    )
}

const UserPage = ({ children }) => {
    const navigate = useNavigate();
    const saved = localStorage.getItem('user')
    useEffect(() => {
        if (saved === '') {
            navigate('/admin')
        }
    }, [saved])
    return (
        <>
            {children}
        </>
    )
}

export default Webrouter;