import axios from '../../plugins/http.service';
const axiosFunction = {
    getSettingById(id) {
        return axios.post("settings/get-setting",id);
    },
    addSetting(data) {
        return axios.post("settings/add-setting",data);
    },
    deleteSetting(id) {
        return axios.post("settings/delete-setting",id);
    },
    editSetting(data) {
        return axios.post("settings/edit-setting",data);
    },
    getContentById(id) {
        return axios.post("content/get-content",id);
    },
    addContent(data) {
        return axios.post("content/add-content",data);
    },
    deleteContent(id) {
        return axios.post("content/delete-content",id);
    },
    editContent(data) {
        return axios.post("content/edit-content",data);
    },
    getTestimonialById(id) {
        return axios.post("testimonial/get-testimonial",id);
    },
    addTestimonial(data) {
        return axios.post("testimonial/add-testimonial",data);
    },
    deleteTestimonial(id) {
        return axios.post("testimonial/delete-testimonial",id);
    },
    editTestimonial(data) {
        return axios.post("testimonial/edit-testimonial",data);
    },
    getPartnerById(id) {
        return axios.post("partner/get-partner",id);
    },
    getPartners() {
        return axios.post("partner/get-partners");
    },
    addPartner(data) {
        return axios.post("partner/add-partner",data);
    },
    deletePartner(id) {
        return axios.post("partner/delete-partner",id);
    },
    editPartner(data) {
        return axios.post("partner/edit-partner",data);
    },
    addTreatment(data) {
        return axios.post("treatment/add-treatment",data);
    },
    getTreatmentById(id) {
        return axios.post("treatment/get-treatment",id);
    },
    deleteTreatment(id) {
        return axios.post("treatment/delete-treatment",id);
    },
    editTreatment(data) {
        return axios.post("treatment/edit-treatment",data);
    },
    getTreatments() {
        return axios.post("treatment/get-treatments");
    },
    getSpecialtyById(id) {
        return axios.post("specialty/get-specialty",id);
    },
    deleteSpecialty(id) {
        return axios.post("specialty/delete-specialty",id);
    },
    editSpecialty(data) {
        return axios.post("specialty/edit-specialty",data);
    },
    addSpecialty(data) {
        return axios.post("specialty/add-specialty",data);
    },
    addDoctor(data) {
        return axios.post("doctor/add-doctor",data);
    },
    deleteDoctor(id) {
        return axios.post("doctor/delete-doctor",id);
    },
    editDoctor(data) {
        return axios.post("doctor/edit-doctor",data);
    },
    getDoctorById(id) {
        return axios.post("doctor/get-doctor",id);
    },
    addContact(data) {
        return axios.post("contact/add-contact",data);
    },
    deleteContact(id) {
        return axios.post("contact/delete-contact",id);
    },
    editContact(data) {
        return axios.post("contact/edit-contact",data);
    },
    getContactById(id) {
        return axios.post("contact/get-contact",id);
    },
    getSpecialties() {
        return axios.get('specialty/get-specialties')
    },
    addPartnerTreatments(data) {
        return axios.post('partner-treatment/add-partner-treatments',data)
    }
}
export default axiosFunction;