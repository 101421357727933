import * as React from 'react';
import partnersData from '../../assets/data/partners';
import './Admin.css'
import data from '../../assets/data/treatments';
import axios from './api';
import PartnersModal from '../../components/Modal/partners-modal';
import AdminDataGrid from '../../components/AdminDataGrid/AdminDataGrid';
import PartnersModalSpecialties from '../../components/Modal/partners-modal-specialties';
import { useState } from 'react';
import { useEffect } from 'react';
const Partners = () => { 
    
    const Button = (params)=>{
     //   console.log(params)
        
        return(
            <PartnersModal id={params.row.id} text={params.field} icon={params.field}/>
        )
    }
    const [rows, setRows] = useState([])
    const [treatments, setTreatments] = useState([])
    useEffect(() => {
        axios.getPartners().then((res) => {
          setRows(res.data.data)
        })
        axios.getTreatments().then((res) => {
            console.log(res.data.data)
          setTreatments(res.data.data)
        })
    }, []);
    const columns = [
        { 
            field: 'name_en', 
            headerName: 'Name En', 
            flex: 1,
        },
        { 
            field: 'name_ar', 
            headerName: 'Name Ar', 
            flex: 1 
        },
        { 
            field: 'description_en', 
            headerName: 'Description En', 
            flex: 3,
        },
        { 
            field: 'description_ar', 
            headerName: 'Description Ar', 
            flex: 3 
        },
        { 
            field: 'website', 
            headerName: 'Website', 
            flex: 1 
        },
        {
            field: 'Treatments',
            headerName: 'Treatments', 
            renderCell: (params)=><PartnersModalSpecialties partnerTreatments={params.row.partnerTreatments} id={params.row.id} treatments={treatments}/>
        },
        {
            field: 'Edit',
            headerName: 'Edit', 
            renderCell: Button
        },
        {
            field: 'Delete',
            headerName: 'Delete', 
            renderCell: Button
        }
    ];

    return (
        <div style={{ height: 700, width: '100%' }}>
            <div className='Title-container'>
                <div className='fs38 Admin-title'>Partners</div>
                <PartnersModal text='Add' />
            </div>
            <AdminDataGrid columns={columns} rows={rows} />
        </div>
    )
}

export default Partners