import React, { useState, useEffect, useContext } from "react";
import "./ContactUs.css";
import "./arContactUs.css";
import { useTranslation } from "react-i18next";
import bannerPic from "../../assets/images/contactBanner.png";
import { useNavigate } from "react-router-dom";
import axios from "./api";
import { Button } from "@mui/material";

import UserContext from '../../context/userContext'
const ContactUs = () => {
  const { t, i18n } = useTranslation();
  const [contactType, setContactType] = useState('');
  const [country, setCountry] = useState([]);
  const { socialMedia } = useContext(UserContext);
  const [submited, setSubmited] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    axios.getCountry().then((res) => {
      setCountry(res.data.data)
    })
  }, []);
  const AddContact = () => {
    var full_name = document.getElementById('user-contact-name').value
    var email = document.getElementById('user-contact-email').value
    var mobile = document.getElementById('user-contact-phone').value
    var country = document.getElementById('user-contact-country').value
    var contact_time = document.getElementById('user-contact-time').value
    var message = document.getElementById('user-contact-message').value
    if (email !== '' || full_name !== '' || mobile !== '' || country !== '' || contact_time !== '' || message) {
      var data = new FormData();
      data.append('full_name', full_name);
      data.append('email', email);
      data.append('mobile', mobile);
      data.append('country', country);
      data.append('contact_time', contact_time);
      data.append('contact_type', contactType);
      data.append('file', document.querySelector('input[type=file]')['files'][0]);
      data.append('message', message);
      axios.addContact(data).then((res) => {
        setSubmited(true)
      })
    } else {
      var el = document.getElementsByClassName('contact-input')
      for (let i = 0; i < el.length; i++) {
        el[i].style.borderColor = "red";
        el[i].classList.add('error')
      }
    }
  };
  return (
    <> {country.length > 0 && < div className={i18n.resolvedLanguage + " contact-page-container"}>
      <div className="image-layer page-image">
        <img className="contact-image" src={bannerPic} alt="" />
      </div>
      <div className="global-container contact-forms">
        <div className="reach-us">
          <div className="raech-us-title fs38">{t('how_to_find_us')}</div>
          <div className="reach-us-infos">
            <ul className="fs18">
              <li>
                {t('address')}
              </li>
              <li><a href={'mailto:' + t('email')}>{t('email')}</a></li>
              <li><a href={'tel:' + t('phone')}>{t('hotline') + ': ' + t('phone')}</a></li>
              <li><a href={'tel:' + t('whatsapp')}>{t('whatsapp_number') + ': ' + t('whatsapp')}</a></li>
            </ul>
            <div class="mapouter">
              <div class="gmap_canvas">
                <iframe width="100%" height="301" id="gmap_canvas" src="https://maps.google.com/maps?q=34.4315860,%2035.8365240&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                </iframe>
                <a href="https://www.embedgooglemap.net"></a>
              </div>
            </div>
            <p className='fs18 m-0 ' ><i onClick={() => { window.open(socialMedia['facebook'].value_en) }} className="fa fa-facebook primaryColor pointer"></i> | <i onClick={() => { window.open(socialMedia['instagram'].value_en) }} className="fa fa-instagram primaryColor pointer"></i></p>

          </div>
        </div>
        <div className="contact-us">
          <div className="contact-us-container">
            <div className="contact-us-title fs38">{t('contact_title')}</div>
            <div className="contact-form-container">
              <div>
                <form className="contact-form">
                  <div className="contact-row">
                    <input
                      className="fs18 contact-input"
                      defaultValue={''}
                      type="text"
                      id='user-contact-name'
                      placeholder={t('contact_name')}
                    />

                    <select
                      onChange={(e) => { setContactType(e.target.value) }}
                      className="fs18 contact-input"
                      defaultValue={''}
                      label={t('contact_country')}
                      id='user-contact-country'>
                      <option value="">{t('contact_country')}</option>
                      {country.map((element) => { return <option value={element.country}>{i18n.language === 'en' ? element.country : element.country_ar}</option> })}
                    </select>
                  </div>
                  <div className="contact-row">
                    <input
                      className="fs18 contact-input"
                      defaultValue={''}
                      type="text"
                      id='user-contact-phone'
                      placeholder={t('contact_phone')}
                    />
                    <input
                      className="fs18 contact-input"
                      defaultValue={''}
                      type="text"
                      id='user-contact-email'
                      placeholder={t('contact_email')}
                    />
                  </div>
                  <div className="contact-row">
                    <input
                      className="fs18 contact-input"
                      defaultValue={''}
                      type="text"
                      id='user-contact-time'
                      placeholder={t('contact_time')}
                    />
                    <select
                      onChange={(e) => { setContactType(e.target.value) }}
                      className="fs18 contact-input"
                      defaultValue={''}
                      id='user-contact-type'>
                      <option value="">{t('contact_type')}</option>
                      <option value="Mobile">{t('bymobile')}</option>
                      <option value="Email">{t('byemail')}</option>
                    </select>
                  </div>
                  <div className="contact-row">
                    <textarea
                      className="fs18 contact-input"
                      defaultValue={''}
                      rows={8}
                      type="text"
                      id='user-contact-message'
                      placeholder={t('contact_message')}
                    />
                  </div>
                  <div className="contact-row">
                    <input id="contactor-file" className="fs18 contact-file" type="file" />
                    <label className="contact-file-label" for="contactor-file">{t('upload')}</label>
                  </div>
                  <div className="submit-button">
                    <div>{submited == true ? t('contact-message-sended') : ''}</div>
                    <button type='button' onClick={AddContact} className="fs18">{t('submit_btn')}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>}</>
  );
};

export default ContactUs;
