import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent } from '@mui/material';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import axios from './api'
import { useState } from 'react';

export default function SpecialtyModal({ text, icon, treatments, id }) {
    const [open, setOpen] = React.useState(false); 
    const [treat,setTreat]=useState(1);
    var data = new FormData();
    const [price,setPrice]=useState('')
    const [nameEn,settNameEn]=useState('')
    const [nameAr,setNameAr]=useState('')

    const handleClickOpen = () => {
        data.append('id', id)
        if (text === 'Edit') {
            axios.getSpecialtyById(data).then((res) => {
                setOpen(true);
                setPrice(res.data.data.price)
                settNameEn(res.data.data.name_en)
                setNameAr(res.data.data.name_ar)
                setTreat(res.data.data.treatment_id)
            })
        }
        else {
            setOpen(true)
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOnConfirm = ()=>{
        if(text==='Delete'){
            DeleteSpecialty()
        }else if(text==='Edit'){
            EditSpecialty()
        }else{
            AddSpecialty()
        }
    }
    const AddSpecialty = () => {
        data.append('treatment_id', treat);
        data.append('name_en', document.getElementById('specialty-name-en').value);
        data.append('name_ar', document.getElementById('specialty-name-ar').value);
        data.append('price', document.getElementById('specialty-cost').value);
        
        axios.addSpecialty(data).then((res) => {
            window.location.reload(true);
        }).catch(function (error) {
            console.log(error)
        });
        setOpen(false);
    };
    const EditSpecialty = () => {
        data.append('id', id)
        data.append('treatment_id', treat);
        data.append('name_en', document.getElementById('specialty-name-en').value);
        data.append('name_ar', document.getElementById('specialty-name-ar').value);
        data.append('price', document.getElementById('specialty-cost').value);
        axios.editSpecialty(data).then((res) => {
            window.location.reload(true);
        }).catch(function (error) {
            console.log(error)
        });
        setOpen(false);
    };
    const DeleteSpecialty = () => {
        console.log(id)
        data.append('id', id)
        axios.deleteSpecialty(data).then((res) => {
            console.log(res)
            window.location.reload(true);
        }).catch(function (error) {
            console.log(error)
        });
        setOpen(false);
    };

    const arr = treatments?.map((treat) => {
        return (
            <MenuItem value={treat.id}>{treat.name_en}</MenuItem>
        )
    })
    const changeTreat = (e) => {
        console.log(e.target.value)
        setTreat(e.target.value)
    }
    return (
        <div className='Buttontest-container'>
            <button className='fs16' onClick={handleClickOpen}>
                {text == 'Add' ? text : ''}<i className={icon == 'Edit' ? 'fa fa-pen' : icon == 'Delete' ? 'fa fa-trash' : ''}></i>
            </button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{text} Specialty</DialogTitle>
                <DialogContent >
                    {icon !== 'Delete' && <div>
                        <div className='dialog-row'>
                            <TextField
                                margin="dense"
                                defaultValue={nameEn}
                                id="specialty-name-en"
                                label="Name in English"
                                type="email"
                                fullWidth
                                variant="standard"
                            />
                            <TextField
                                margin="dense"
                                defaultValue={nameAr}
                                id="specialty-name-ar"
                                label="Name in Arabic"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                        <TextField
                                margin="dense"
                                defaultValue={price}
                                id="specialty-cost"
                                label="Enter price"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        <FormControl variant="standard" sx={{ minWidth: '100%' }}>
                            <InputLabel id="testimonial-language-label">Choose The Treatment</InputLabel>
                            <Select
                                defaultValue={treat}
                                onChange={changeTreat}
                                labelId='testimonial-language-label'
                                variant='standard'
                                sx={{ width: '100%', marginTop: '30px' }}
                            >
                                {arr}
                            </Select>
                        </FormControl>

                    </div>}
                    {icon == 'Delete' && <p>Are you sure you want to delete it?</p>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleOnConfirm}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
