import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContent } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel, {
  formControlLabelClasses,
} from "@mui/material/FormControlLabel";
import "./modals.css";
import axios from "./api";

const PartnersModalSpecialties = ({ partnerTreatments, treatments, id }) => {
  const [open, setOpen] = React.useState(false);
  const [newPartnerTreatments, setNewPartnerTreatments] = React.useState(false);

  const arr = treatments.map((treat, index) => {
    return (
      <FormControlLabel
        key={index}
        control={
          <input
            name="treatment"
            id="treatmentsOffered"
            type="checkbox"
            value={treat.id}
            defaultChecked={
              partnerTreatments.filter((e) => {
                return e.treatment_id == treat.id;
              }).length == 0
                ? false
                : true
            }
          />
        }
        label={treat.name_en}
      />
    );
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnConfirm = () => {
    var checkboxes = document.getElementsByName("treatment");
    var values = [];
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked === true) {
        values.push(checkboxes[i].value);
      }
    }
    Add(values);
  };
  const Add = (s) => {
    var data = new FormData();
    data.append("partner_id", id);
    data.append("treatments_id", JSON.stringify(s));

    axios.addPartnerTreatments(data).then((res) => {
        window.location.reload(true);
    })
  };
  return (
    <div className="Buttontest-container">
      <button className="fs16" onClick={handleClickOpen}>
        <i className="fa fa-capsules" />
      </button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Offered Treatments</DialogTitle>
        <DialogContent>
          <FormGroup>{arr}</FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleOnConfirm}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PartnersModalSpecialties;
